import React from "react";
import { Button } from "react-scroll";
import { HiArrowNarrowRight } from "react-icons/hi";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className=" text-indigo-500">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Mike Walcott
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          I'm a Front End Developer
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          As a front-end developer, I specialize in crafting captivating user
          interfaces that seamlessly blend creativity with functionality. My
          code is not just lines; it's the brushstrokes that paint engaging
          websites and applications, ensuring they not only look stunning but
          also perform flawlessly across devices.
        </p>
        <div>
          <button
            className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-indigo-500 hover:border-indigo-500"
            to="work"
            smooth={true}
            duration={500}
          >
            View Work
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
