import React, { useState } from "react";
import { RiCloseLine, RiMenu4Fill } from "react-icons/ri";
import { FaGithub, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/mw-logo.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleSetActive = (to) => {
    console.log(to);
  };

  return (
    <header className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      <div>
        <img src={Logo} alt="Logo Image" style={{ width: "65px" }} />
      </div>

      {/* Menu */}
      <ul className="hidden md:flex">
        <li>
          <Link
            activeClass="active"
            to="home"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            spy={true}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="about"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            spy={true}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="skills"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            spy={true}
          >
            Skills
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="work"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            spy={true}
          >
            Work
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to="contact"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            spy={true}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <RiMenu4Fill size={30} /> : <RiCloseLine size={30} />}
      </div>

      {/* Mobile Menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link
            activeClass="active"
            onClick={handleClick}
            to="home"
            smooth={true}
            duration={500}
          >
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            activeClass="active"
            onClick={handleClick}
            to="about"
            smooth={true}
            duration={500}
          >
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            activeClass="active"
            onClick={handleClick}
            to="skills"
            smooth={true}
            duration={500}
          >
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            activeClass="active"
            onClick={handleClick}
            to="work"
            smooth={true}
            duration={500}
          >
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            activeClass="active"
            onClick={handleClick}
            to="contact"
            smooth={true}
            duration={500}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Social Icons */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-indigo-600 hover:bg-indigo-800">
            <a
              className="flex justify-between items-center w-full text-gray-300 pl-2"
              href="https://www.linkedin.com/in/mwalcott"
              target="_blank"
              rel="nofollow"
            >
              LinkedIn <FaLinkedinIn size={30} />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-indigo-600 hover:bg-indigo-800">
            <a
              className="flex justify-between items-center w-full text-gray-300 pl-2"
              href="mailto:info@mikewalcott.com"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-indigo-600 hover:bg-indigo-800">
            <a
              className="flex justify-between items-center w-full text-gray-300 pl-2"
              href="/"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
