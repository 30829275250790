import React from "react";
import { FaWordpressSimple } from "react-icons/fa";
import ArcWest from "../assets/projects/arcwestarchitects.jpg";
import Boraam from "../assets/projects/boraamindustries.jpg";
import Mobility from "../assets/projects/mobilitycg.jpg";
import Moorpark from "../assets/projects/moorparkvet.jpg";
import Pindler from "../assets/projects/pindler.jpg";
import Summit from "../assets/projects/summit-materials.jpg";

const Work = () => {
  return (
    <div name="work" className="w-full md:h-screen text-gray-300 bg-[#0a192f]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-indigo-500">
            Work
          </p>
          <p className="py-6">// Check out some of my recent work</p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${ArcWest})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100 ease-in-out">
              <span className="text-2xl font-bold text-white tracking-wider">
                ArcWest Architects{" "}
                <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://arcwestarchitects.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Boraam})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider">
                Boraam Industries{" "}
                <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://boraamindustries.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Mobility})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider">
                Mobility CG <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://mobilitycg.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Moorpark})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider">
                Moorpark Vet <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://moorparkvet.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Pindler})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider text-center block">
                Pindler <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://www.pindler.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>

          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Summit})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider">
                Summit Materials
                <FaWordpressSimple size={30} className="mx-auto" />
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://summit-materials.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    View Website
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
