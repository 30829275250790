import React from "react";

import {
  FaHtml5,
  FaCss3Alt,
  FaReact,
  FaNodeJs,
  FaWordpress,
  FaPhp,
  FaBootstrap,
} from "react-icons/fa6";

import { SiTailwindcss } from "react-icons/si";

const Skills = () => {
  return (
    <div name="skills" className="w-full h-screen bg-[#0a192f] text-gray-300">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-indigo-500">
            Skills
          </p>
          <p className="py-4">// These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-6 text-center py-8">
          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaWordpress size={70} className="mx-auto" />
            <p className="mt-4">Wordpress</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaHtml5 size={70} className="mx-auto" />
            <p className="mt-4">HTML</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaCss3Alt size={70} className="mx-auto" />
            <p className="mt-4">CSS</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaReact size={70} className="mx-auto" />
            <p className="mt-4">React</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaNodeJs size={70} className="mx-auto" />
            <p className="mt-4">Node</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <SiTailwindcss size={70} className="mx-auto" />
            <p className="mt-4">Tailwind</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaBootstrap size={70} className="mx-auto" />
            <p className="mt-4">Bootstrap</p>
          </div>

          <div className="bg-indigo-900 rounded py-6 shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <FaPhp size={70} className="mx-auto" />
            <p className="mt-4">PHP</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
